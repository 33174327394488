<template>
  <div>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="$refs.modalTemplate.show(), novoTemplate=true, clearCamposTemplate(), edit= false"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Template email
        </b-button>
      </b-col>
    </b-row>
    <!-- Criando o modal -->
    <b-modal
      ref="modalTemplate"
      size="sm"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          class="mt-1"
        >
          Credor :
          <b-form-select
            v-model="template.credores_id"
            :options="optionsCredores"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Título :
          <b-form-input
            v-model="template.titulo"
            placeholder="Título"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          Mensagem :
          <b-form-textarea
            v-model="template.mensagem"
            placeholder="Mensagem"
          />
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-form-checkbox
            v-model="template.status"
            checked="true"
            name="check-button"
            switch
            inline
          >
            Status :
          </b-form-checkbox>
        </b-col>
      </b-row>
      <hr>
      <b-row
        v-if="novoTemplate"
        align-h="end"
        class="mt-1"
      >
        <b-col cols="auto">
          <b-button
            variant="danger"
            @click="$refs.modalTemplate.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="storeTemplate"
          >
            Cadastrar
          </b-button>
        </b-col>
      </b-row>
      <!-- novoTemplate = false INICIO DO MODAL COM BOTAO SALVAR DO EDIT -->
      <b-row
        v-if="!novoTemplate"
        align-h="end"
        class="mt-1"
      >
        <b-col
          cols="auto"
        >
          <b-button
            variant="danger"
            @click="$refs.modalTemplate.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col
          cols="auto"
        >
          <b-button
            variant="primary"
            @click="updateTemplateEmail"
          >
            <feather-icon
              icon="SaveIcon"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- </b-modal> -->
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-row align-h="center">
            <b-col cols="8">
              <b-form-input
                v-model="tableTemplateEmail.filter"
                type="search"
                placeholder="Pesquisar"
                class="mb-2"
                size="sm"
              />
            </b-col>
          </b-row>
          <b-table
            responsive
            hover
            bordered
            striped
            :filter="tableTemplateEmail.filter"
            :current-page="tableTemplateEmail.currentPage"
            :per-page="tableTemplateEmail.perPage"
            :fields="tableTemplateEmail.fields"
            :items="tableTemplateEmail.items"
          >
            <template #cell(detalhes)="data">
              <b-row align-h="between">
                <!-- <b-col>
                  <feather-icon
                    class="color-icon"
                    icon="EyeIcon"
                    @click="$router.push({name: 'administrativo.contrato', params: {id: data.item.id}})"
                  />
                </b-col> -->
                <b-col>
                  <feather-icon
                    class="color-icon"
                    icon="EditIcon"
                    @click="rowClicked(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    class="trash-icon"
                    icon="TrashIcon"
                    @click="deleteFornecedor(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
          <b-pagination
            v-model="tableTemplateEmail.currentPage"
            :total-rows="tableTemplateEmail.totalRows"
            :per-page="tableTemplateEmail.perPage"
            align="center"
            size="sm"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BButton,
  BCard,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import axios from '@/../axios-auth'

export default {
  components: {
    FeatherIcon,
    BRow,
    BCol,
    BButton,
    BCard,
    BTable,
    BPagination,
    BModal,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      novoTemplate: true,
      tableTemplateEmail: {
        filter: '',
        fields: [
          'id',
          'titulo',
          'mensagem',
          'status',
          'credor',
          { key: 'detalhes', label: 'ações', class: 'text-center' },
        ],
        items: [],
        totalRows: 1,
        perPage: 10,
        currentPage: 1,
      },
      template: {
        titulo: '',
        mensagem: '',
        status: true,
        credores_id: '',
      },

      optionsCredores: [],
      fornecedorId: '',
    }
  },
  created() {
    this.getTemplates()
    this.getCredores()
  },

  methods: {
    // LIMPAR CAMPOS
    clearCamposTemplate() {
      this.template = {
        titulo: '',
        mensagem: '',
        status: true,
        credores_id: '',
      }
    },

    // EDITAR TEMPLATE
    rowClicked(item) {
      this.fornecedorId = item.id
      axios.get(`api/v1/template_email/edit/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.template = res.data.dados.TemplateEmail
        })
      this.edit = true
      this.novoTemplate = false
      this.$refs.modalTemplate.show()
    },

    // CADASTAR NOVO TEMPLATE EMAIL
    storeTemplate() {
      axios.post('api/v1/template_email/store', this.template, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$refs.modalTemplate.hide()
          this.getTemplates()
          this.$swal({
            title: 'Template cadastrado com sucesso',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.getTemplates()
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
      this.clearCamposTemplate()
    },

    // LISTAR CREDORES
    getCredores() {
      axios.get('api/v1/credores', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.optionsCredores.push({
              text: dt.nome,
              value: dt.id,
            })
          })
        })
    },

    // TEMPLATES EMAIL
    getTemplates() {
      this.tableTemplateEmail.items = []
      axios.get('api/v1/template_email', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.TemplateEmail.map(dt => {
            this.tableTemplateEmail.items.push({
              id: dt.id,
              titulo: dt.titulo,
              mensagem: dt.mensagem,
              status: dt.status,
              credor: dt.credores.nome,
              created_at: dt.created_at,
              updated_at: dt.updated_at,
            })
          })
          this.tableTemplateEmail.items.sort((a, b) => new Date(b.created_at && b.updated_at) - new Date(a.created_at && a.updated_at))
          this.tableTemplateEmail.totalRows = this.tableTemplateEmail.items.length
        })
    },

    // DELETAR TEMPLATE
    deleteFornecedor(item) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o template ${item.id}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/template_email/delete/${item.id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$refs.modalTemplate.hide()
                this.getTemplates()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Template Email deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Template!',
                  text: error.response.data.mensagem,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            })
        }
      })
    },
    // UDPATE TEMPLATE
    async updateTemplateEmail() {
      const body = this.template
      await axios.put(`api/v1/template_email/update/${this.fornecedorId}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/jason',
        },
      }).then(() => {
        this.$refs.modalTemplate.hide()
        this.getTemplates()
        this.$swal({
          icon: 'success',
          title: 'Salvo!',
          text: 'Template salvo com sucesso!',
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch(error => {
        this.$swal({
          title: 'Erro ao Salvar!',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>
